<template>
  <div class="is-flex is-flex-direction-column gap-5">
    <p class="averta-black label-18 mb-4">Detail Informasi Item pekerjaan</p>

    <div class="flex-row-start c-w-100">
      <div class="btn-tabs-2 mr-3" @click="handleClickTab('BaseInformation')" :class="{'is-active': tabActive === 'BaseInformation'}">
        <p>Informasi Dasar</p>
      </div>
      <div class="btn-tabs-2 mr-3" @click="handleClickTab('PriceCount')" :class="{'is-active': tabActive === 'PriceCount'}">
        <p>Perhitungan Harga</p>
      </div>
    </div>

    <div class="is-flex is-flex-direction-column gap-5 c-w-100" v-if="tabActive === 'BaseInformation'">
      <div class="bg-lightgray border-radius-12 overflow-visible p-4">
        <p class="averta-black label-18 mb-2">Kuota Sesi Pengerjaan</p>
        <div class="input-wrapper-grid">
          <div>
            <p class="mb-2">Volume Maksimal (Untuk 8 Jam Pengerjaan) <span class="color-grey averta-reguler-italic">(Opsional)</span></p>
            <InputComponents :type="'number'" :placeholder="0" :value="dataAPIUnitPrice && dataAPIUnitPrice.max_volume" :disabled="false" />
          </div>
          <div v-if="!isLoading">
            <p class="mb-2">Satuan <span class="color-red">*</span></p>
            <InputAutocompleteSatuan :value="unitSelected" @selectedUnit="$event => handleSelectUnit($event)" />
          </div>
          <div>
            <p class="mb-2">Kuota Sesi Pengerjaan <span class="color-grey averta-reguler-italic">(Opsional)</span></p>
            <InputComponents :type="'number'" :placeholder="0" :textBold="true" :value="dataAPIUnitPrice && dataAPIUnitPrice.session_quota" :disabled="true" />
          </div>
        </div>
      </div>

      <div class="bg-lightgray border-radius-12 overflow-visible p-4">
        <p class="averta-black label-18 mb-2">Rincian Harga Penawaran</p>
        <div class="input-wrapper-grid">
          <div>
            <p class="mb-2">Harga Material <span class="color-grey averta-reguler-italic">(Opsional)</span></p>
            <InputPrice :value="materialPrice" @change="$event => handleChangeInput($event, 'material_price')" />
          </div>
          <div>
            <p class="mb-2">Harga Jasa <span class="color-grey averta-reguler-italic">(Opsional)</span></p>
            <InputPrice :value="servicePrice" @change="$event => handleChangeInput($event, 'service_price')" />
          </div>
          <div>
            <p class="mb-2">Harga Satuan Pekerjaan <span class="color-red">*</span></p>
            <InputPrice :value="unitPrice" @change="$event => handleChangeInput($event, 'unit_price')" />
          </div>
          <!-- <div v-if="!isLoading">
            <p class="mb-2">Satuan <span class="color-red">*</span></p>
            <InputAutocompleteSatuan :value="unitSelected" @selectedUnit="$event => handleSelectUnit($event)" />
          </div> -->
        </div>
      </div>
    </div>

    <CalculationPriceOffering v-if="tabActive === 'PriceCount'" />
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import { formatCurrency } from '@/helpers'

export default {
  name: 'InputPriceItemWorking',
  components: {
    InputPrice: () => import('@/components/input/InputPrice.vue'),
    InputAutocompleteSatuan: () => import('@/components/input/InputAutocompleteSatuan.vue'),
    CalculationPriceOffering: () => import('./CalculationPriceOffering.vue'),
    InputComponents: () => import('@/components/input/InputComponents.vue')
  },
  data () {
    return {
      isLoading: false,
      servicePrice: null,
      materialPrice: null,
      unitPrice: null,
      unitSelected: null,
      max_volume: null,
      session_quota: null,
      tabActive: 'BaseInformation',
      type: '',
      placeholder: '',
      textBold: true,
      disabled: true
    }
  },
  computed: {
    ...mapGetters({
      reset: 'unitPrice/getResetUnitPrice',
      dataAPIUnitPrice: 'unitPrice/getDataAPIUnitPrice'
    })
  },
  methods: {
    calculateHargaSatuan () {
      this.unitPrice = this.formatThousand(this.removeComma(this.materialPrice) + this.removeComma(this.servicePrice))
    },
    updateFromJasa () {
      this.materialPrice = this.formatThousand(this.removeComma(this.unitPrice) - this.removeComma(this.servicePrice))
      // this.calculateHargaSatuan()
    },
    updateFromMaterial () {
      this.servicePrice = this.formatThousand(this.removeComma(this.unitPrice) - this.removeComma(this.materialPrice))
      // this.calculateHargaSatuan()
    },
    updateFromSatuan () {
      const unitRemove = this.removeComma(this.unitPrice)
      const halfUnit = Math.max(unitRemove / 2, 0)
      this.materialPrice = this.formatThousand(Math.floor(halfUnit))
      this.servicePrice = this.formatThousand(Math.ceil(halfUnit))
    },
    handleChangeInput (value, type) {
      const temp = value.toString().replace(/\D/g, '')
      switch (type) {
        case 'service_price':
          if (this.materialPrice === '0' && temp === '0') {
            this.servicePrice = '0'
          } else {
            this.servicePrice = this.formatThousand(temp)
            this.updateFromJasa()
          }
          break
        case 'material_price':
          if (this.servicePrice === '0' && temp === '0') {
            this.materialPrice = '0'
          } else {
            this.materialPrice = this.formatThousand(temp)
            this.updateFromMaterial()
          }
          break
        case 'unit_price':
          this.unitPrice = this.formatThousand(temp)
          if (this.materialPrice !== '0' || this.servicePrice !== '0') {
            this.updateFromSatuan()
          }
          break
      }

      this.$store.commit('unitPrice/setUnitPrice', {
        service_price: this.servicePrice,
        material_price: this.materialPrice,
        unit_price: this.unitPrice
      })
    },
    handleSelectUnit (option) {
      this.$store.commit('unitPrice/setUnitPrice', { unit: option })
      this.unitSelected = option
    },
    formatPrice (value) {
      if (value !== null) {
        const temp = value.toString().replace(/\D/g, '')
        return Number(temp).toLocaleString('id-ID')
      } else {
        return ''
      }
    },
    formatThousand (value) {
      return formatCurrency(value, true) === '-' ? 0 : formatCurrency(value, true)
    },
    removeComma (value) {
      const convertedNumber =
        typeof value === 'string'
          ? parseFloat(value.replace(/\./g, '').replace(',', '.'))
          : value

      return convertedNumber
    },
    handleClickTab (tab) {
      this.tabActive = tab
    }
  },
  watch: {
    reset (val) {
      this.servicePrice = null
      this.materialPrice = null
      this.unitPrice = null
      this.unitSelected = null
    }
  },
  mounted () {
    if (this.dataAPIUnitPrice) {
      this.servicePrice = this.formatPrice(this.dataAPIUnitPrice.service_price)
      this.materialPrice = this.formatPrice(this.dataAPIUnitPrice.material_price)
      this.unitPrice = this.formatPrice(this.dataAPIUnitPrice.unit_price)

      let tempUnit
      if ('unit' in this.dataAPIUnitPrice) {
        tempUnit = this.dataAPIUnitPrice.unit
      } else {
        tempUnit = {
          id: this.dataAPIUnitPrice.unit_id,
          name: this.dataAPIUnitPrice.unit_name
        }
      }
      this.unitSelected = tempUnit
      this.isLoading = true
      setTimeout(() => {
        this.isLoading = false
      }, 300)
    }
  }
}
</script>

<style scoped>
.input-wrapper-grid {
  display: grid;
  column-gap: 15px;
  grid-template-columns: repeat(auto-fit, minmax(10rem, 1fr));
}
</style>
